<template>
<div class="text-left">
    <div class="text-md text-gray-600 dark:text-white/75 font-semibold text-left">
        {{ bots.localization['bot_settings_title'] }}
    </div>
    <n-button
        strong
        secondary
        size="small"
        class="rounded-md mt-2"
        @click="showCreateNewPreset = true">
        {{ bots.localization['bot_setting_save_button'] }}
    </n-button>
</div>

<div class="mt-6">
    <!-- rate_cover -->
    <rb-select
        id="rate_cover"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_rate_cover_f']"
        :help="bots.help['rate_cover']"
        :options="rateCoverOptions"
        :status="!!bots.errorsForm?.rate_cover ? 'error' : undefined"
        :msg="bots.errorsForm?.rate_cover?.msg"
        v-model:value="dataForm.settings.rate_cover"
        @update:modelValue="delete bots.errorsForm.rate_cover" />

    <!-- first_order_indent -->
    <rb-select
        id="first_order_indent"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_first_order_indent_f']"
        :help="bots.help['first_order_indent']"
        :options="firstOrderIndentOptions"
        :status="!!bots.errorsForm?.first_order_indent ? 'error' : undefined"
        :msg="bots.errorsForm?.first_order_indent?.msg"
        v-model:value="dataForm.settings.first_order_indent"
        @update:modelValue="delete bots.errorsForm.first_order_indent" />

    <!-- rate_mode -->
    <rb-select
        id="rate_mode"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_price_rate_to_use_f']"
        :help="bots.help['price_rate_to_use']"
        :options="rateModesOptions"
        :status="!!bots.errorsForm?.rate_mode ? 'error' : undefined"
        :msg="bots.errorsForm?.rate_mode?.msg"
        @update:modelValue="delete bots.errorsForm.rate_mode"
        v-model:value="dataForm.settings.rate_mode" />

    <!-- order_matrix -->
    <rb-select
        id="order_matrix"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_orders_matrix_f']"
        :help="bots.help['orders_matrix']"
        :options="orderMatrixOptions"
        :status="!!bots.errorsForm?.order_matrix ? 'error' : undefined"
        :msg="bots.errorsForm?.order_matrix?.msg"
        @update:modelValue="delete bots.errorsForm.order_matrix"
        v-model:value="dataForm.settings.order_matrix">
        <template #afterTitle>
            <n-button
                strong
                size="tiny"
                color="#f3731e"
                class="ml-2"
                @click="orderMatrixModal = true">
                    <span class="text-white font-semibold">PRO</span>
            </n-button>
            <n-popover
                trigger="hover"
                placement="top"
                class="bg-main rounded-md">
                <template #trigger>
                    <n-button
                        strong
                        size="tiny"
                        class="ml-2"
                        :color="gl.mainColor"
                        :loading="orderMatrixLoading"
                        :disabled="dataForm.settings.order_matrix == -1 || orderMatrixLoading"
                        @click="onViewOrderMatrix">
                        <n-icon class="text-xl text-white">
                            <CellularData220Filled />
                        </n-icon>
                    </n-button>
                </template>
                <span class="text-white text-xs" v-html="bots.localization['bot_additional_settings_logarithmic_scale_view_orders_matrix_button']"></span>
            </n-popover>
        </template>
    </rb-select>
</div>

<template v-if="partOrderValueShow">
    <n-divider />
    <!-- part_orders -->
    <section>
        <div class="mt-4">
            <rb-checkbox
                :nowrap="false"
                :label="'- ' + bots.localization['bot/settings/part_orders_enabled']"
                :help="bots.help['part_orders_enabled']"
                v-model:checked="dataForm.settings.part_orders_enabled" />
            <rb-matrix-count
                class="mt-4 pb-1"
                :max="compMatrixMaxCount"
                v-model="dataForm.settings.part_orders_value"
                :disabled="!dataForm.settings.part_orders_enabled"/>
            
            <n-divider />
            <rb-checkbox
                v-if="!isExchangeFutures && dataForm.settings.part_orders_enabled"
                :nowrap="false"
                :label="'- ' + bots.localization['bot/settings/part_orders_unstopable']"
                v-model:checked="dataForm.settings.part_orders_unstopable" />
        </div>
        <!-- Restruct Cycle -->
        <rb-checkbox
            class="mt-4"
            :nowrap="false"
            :label="'- ' + bots.localization['bots/settings/restruct_cycle/enabled']"
            :help="bots.help['restruct_cycle_enabled']"
            v-model:checked="dataForm.settings.restruct_cycle_enabled" />
        <rb-select
            v-if="dataForm.settings.restruct_cycle_enabled"
            class="mt-4"
            id="restruct_cycle_i"
            :defaultValue="-1"
            :label="bots.localization['bots/settings/restruct_cycle/restruct_cycle_i']"
            :help="bots.help['restruct_cycle_i']"
            :options="restructCycleOptions"
            :status="!!bots.errorsForm?.restruct_cycle_i ? 'error' : undefined"
            :msg="bots.errorsForm?.restruct_cycle_i?.msg"
            v-model:value="dataForm.settings.restruct_cycle_i"
            @update:value="delete bots.errorsForm.restruct_cycle_i" />
        <rb-select
            v-if="dataForm.settings.restruct_cycle_enabled"
            class="mt-4"
            id="restruct_cycle_rate_cover"
            :defaultValue="-1"
            :label="bots.localization['bots/settings/restruct_cycle/restruct_cycle_rate_cover']"
            :options="restructCycleRateCoverOptions"
            :status="!!bots.errorsForm?.restruct_cycle_rate_cover ? 'error' : undefined"
            :msg="bots.errorsForm?.restruct_cycle_rate_cover?.msg"
            v-model:value="dataForm.settings.restruct_cycle_rate_cover"
            @update:value="delete bots.errorsForm.restruct_cycle_rate_cover" >
            <template #afterTitle>
                <n-popover
                    trigger="hover"
                    placement="top"
                    class="bg-main rounded-md">
                    <template #trigger>
                        <n-button
                            strong
                            size="tiny"
                            class="ml-2"
                            :color="gl.mainColor"
                            :loading="orderMatrixLoading"
                            :disabled="dataForm.settings.order_matrix == -1 || orderMatrixLoading"
                            @click="onViewOrderMatrix">
                            <n-icon class="text-xl text-white">
                                <CellularData220Filled />
                            </n-icon>
                        </n-button>
                    </template>
                    <span class="text-white text-xs" v-html="bots.localization['bot_additional_settings_logarithmic_scale_view_orders_matrix_button']"></span>
                </n-popover>
            </template>
        </rb-select>
    </section>
</template>

<n-divider />
<rb-checkbox
    v-if="isExchangeTrailingstop"
    :label="'- ' + bots.localization['bots/trailingstop/enabled/label']"
    :help="bots.help['bots/trailingstop/enabled/label']"
    v-model:checked="dataForm.settings.trailingstop_enabled" />
<div class="mt-4">
    <!-- profit -->
    <rb-select
        id="profit"
        :defaultValue="-1"
        :label="bots.localization['bot_profit_f']"
        :help="bots.help['profit']"
        :options="profitOptions"
        :status="!!bots.errorsForm?.profit ? 'error' : undefined"
        :msg="bots.errorsForm?.profit?.msg"
        @update:modelValue="delete bots.errorsForm.profit"
        v-model:value="dataForm.settings.profit" />

    <!-- trailingstop_rate -->
    <!-- :class="dataForm.settings.trailingstop_enabled ? 'w-4/12' : 'w-2/12'" -->
    <rb-select
        v-if="dataForm.settings.trailingstop_enabled"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bots/trailingstop/rate/label']"
        :help="bots.help['bots/trailingstop/rate/label']"
        :options="trailingstopRateOptions"
        v-model:value="dataForm.settings.trailingstop_rate" />

    <!-- profit_coin -->
    <rb-select
        v-if="isProfitCoinEditable"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_profit_coin_f']"
        :help="bots.help['profit_coin']"
        :options="depoPairsOptions"
        v-model:value="dataForm.settings.profit_coin" />
    <div v-else class="mt-4">
        <div class="flex">
            <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">{{ bots.localization['bot_profit_coin_f'] }}</div>
            <n-popover trigger="hover" placement="top" class="max-w-[200px] rounded-md bg-main">
                <template #trigger>
                    <n-icon class="text-main cursor-pointer text-xl mx-2">
                        <QuestionCircle20Filled />
                    </n-icon>
                </template>
                <span class="text-xs text-white" v-html="bots.help['profit_coin']"></span>
            </n-popover>
        </div>

        <div v-if="dataForm.settings.profit_coin && dataForm.settings.profit_coin != -1" class="flex items-center mt-2">
            <rb-coin class="w-6" :coin="currency(coinSymbolForProfitCoin)" />
            <div class="font-semibold text-gray-600 dark:text-white/75 ml-2">{{ currency(coinSymbolForProfitCoin).title }}</div>
        </div>
    </div>

    <!-- bot_cycle_up_f -->
    <rb-select
        id="cycle_up"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_cycle_up_f']"
        :help="bots.help['cycle_up']"
        :options="cycleUpOptions"
        :status="!!bots.errorsForm?.cycle_up ? 'error' : undefined"
        :msg="bots.errorsForm?.cycle_up?.msg"
        @update:modelValue="delete bots.errorsForm.cycle_up"
        v-model:value="dataForm.settings.cycle_up" />

    <n-divider />
    <div class="mt-4">
        <rb-checkbox
            :label="bots.localization['ightening_timer'] || 'Таймер подтяжки (Время в минутах)'"
            :help="bots.help['ightening_timer_help'] || 'Таймер подтяжки (Время в минутах)'"
            :checked="!!~hasFilter('cycle_up:check:time')"
            @update:checked="triggerFilter('cycle_up:check:time')" />

        <rb-input-number
            v-if="!!~hasFilter('cycle_up:check:time')"
            :min="1"
            :max="60"
            class="mt-2 w-full"
            :value="dataForm.start_filters[hasFilter('cycle_up:check:time')].value"
            @update:value="dataForm.start_filters[hasFilter('cycle_up:check:time')].value = $event" />
    </div>
</div>

<div v-if="!dataForm.settings.simulate">
    <n-divider />
    <!-- sleep_before_cancel -->
    <rb-select
        id="sleep_before_cancel"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_sleep_before_cancel_f']"
        :help="bots.help['sleep_before_cancel_orders_for_cycle_up']"
        :options="sleepBeforeCancelOptions"
        :status="!!bots.errorsForm?.sleep_before_cancel ? 'error' : undefined"
        :msg="bots.errorsForm?.sleep_before_cancel?.msg"
        @update:modelValue="delete bots.errorsForm.sleep_before_cancel"
        v-model:value="dataForm.settings.sleep_before_cancel" />

    <!-- sleep_before_up -->
    <rb-select
        id="sleep_before_up"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_sleep_after_cancel_f']"
        :help="bots.help['sleep_before_cycle_up_after_cancel_orders']"
        :options="sleepBeforeUpOptions"
        :status="!!bots.errorsForm?.sleep_before_up ? 'error' : undefined"
        :msg="bots.errorsForm?.sleep_before_up?.msg"
        @update:modelValue="delete bots.errorsForm.sleep_before_up"
        v-model:value="dataForm.settings.sleep_before_up" />

    <!-- sleep_after_done -->
    <rb-select
        id="sleep_after_done"
        class="mt-4"
        :defaultValue="-1"
        :label="bots.localization['bot_sleep_after_cycle_f']"
        :help="bots.help['sleep_after_end_of_cycle']"
        :options="sleepAfterDoneOptions"
        :status="!!bots.errorsForm?.sleep_after_done ? 'error' : undefined"
        :msg="bots.errorsForm?.sleep_after_done?.msg"
        @update:modelValue="delete bots.errorsForm.sleep_after_done"
        v-model:value="dataForm.settings.sleep_after_done" />
</div>

<base-drawer-mobile
    :label="bots.localization['bot_settings_save_modal_title']"
    v-model:show="showCreateNewPreset">
    <template #default>
        <rb-input
            class="mt-4"
            :label="newPresetModel.name.title"
            :status="newPresetModel.name.status"
            :msg="newPresetModel.name.msg"
            :placeholder="newPresetModel.name.placeholder"
            v-model:value="newPresetModel.name.value"
            @update:value="newPresetModel.name.msg = null, newPresetModel.name.status = null" />
        <rb-input
            class="mt-4"
            type="textarea"
            :label="newPresetModel.description.title"
            :status="newPresetModel.description.status"
            :msg="newPresetModel.description.msg"
            :placeholder="newPresetModel.description.placeholder"
            v-model:value="newPresetModel.description.value" />
    </template>
    <template #footer>
        <n-button
            strong
            class="rounded-md text-white/90 w-full"
            :color="gl.mainColor"
            :disabled="newPresetLoading"
            :loading="newPresetLoading"
            @click="onCreateNewPreset">
            {{ bots.localization['bot_auto_switch_type_volatility_analyzer_save_preset_save_button'] }}
        </n-button>
    </template>
</base-drawer-mobile>

<base-drawer-mobile
    :label="bots.localization['bot_orders_matrix_pro_button_modal_matrix_name_f']"
    v-model:show="orderMatrixModal">
    <template #default>
        <rb-input
            class="mt-4"
            :label-width="230"
            :label="newOrderMatrixModel.name.title"
            :status="newOrderMatrixModel.name.status"
            :msg="newOrderMatrixModel.name.msg"
            :placeholder="newOrderMatrixModel.name.placeholder"
            v-model:value="newOrderMatrixModel.name.value" />
        
        <div class="mt-4">
            <div class="">
                <div class="text-xs text-gray-600 dark:text-white/75">{{ newOrderMatrixModel.amount.title }}</div>

                <div class="flex justify-between mt-2">
                    <n-button
                        strong
                        class="rounded-md text-white/90"
                        :color="gl.mainColor"
                        :disabled="newOrderMatrixModel.amount.value == 0"
                        @click="orderMatrixDec">-</n-button>
                    <n-input
                        class="flex-grow mx-2 text-center rounded-md"
                        type="text"
                        :allow-input="onlyAllowNumber"
                        v-model:value="newOrderMatrixModel.amount.value"
                        :status="newOrderMatrixModel.amount.status"
                        :placeholder="newOrderMatrixModel.amount.placeholder"/>
                    <n-button
                        strong
                        class="rounded-md text-white/90"
                        :color="gl.mainColor"
                        :disabled="newOrderMatrixModel.amount.value == 100"
                        @click="orderMatrixInc">+</n-button>
                </div>
            </div>

            <div v-if="newOrderMatrixModel.matrix.status === 'error'" class="w-full mt-2 text-red-400 text-right text-xs">
                {{ newOrderMatrixModel.matrix.msg }}
            </div>
        </div>

        <template v-if="newOrderMatrixModel.amount.value > 0">
            <n-alert
                class="mt-4 rounded-md"
                :show-icon="false"
                :type="isOrderMatrixCorrect ? 'success' : 'error'">
                <span class="text-[10px]" v-html="bots.localization['bot_orders_matrix_pro_button_modal_matrix_partition']"></span>
                <span class="text-[10px]">{{ omPoolToSpreadLabel }}</span>
            </n-alert>
        </template>

        <div
            v-if="newOrderMatrixModel.amount.value > 0"
            class="flex flex-wrap mt-4">
            <div
                v-for="(_, i) in +newOrderMatrixModel.amount.value"
                :key="i"
                class="w-6/12 flex items-center mb-3 relative">
                <span class="text-lg text-gray-600 dark:text-white/75 whitespace-nowrap">{{ i + 1 }}:</span>
                <n-input
                    class="mx-2 rounded-md text-base"
                    size="large"
                    type="text"
                    :allow-input="onlyAllowNumberMatrix"
                    :placeholder="null"
                    v-model:value="newOrderMatrixModel.matrix.value[i]" />
                <div class="absolute right-[42px] top-1/2 -translate-y-1/2">
                    <n-button-group vertical class="flex h-full rounded-md">
                        <n-button
                            size="tiny"
                            class="h-full text-white/90"
                            :color="gl.mainColor"
                            @click="orderMatrixIncEl(i)">
                            <span class="text-base leading-none">+</span>
                        </n-button>
                        <n-button
                            size="tiny"
                            class="h-full text-white/90"
                            :color="gl.mainColor"
                            @click="orderMatrixDecEl(i)">
                            <span class="text-base leading-none">-</span>
                        </n-button>
                    </n-button-group>
                </div>
                <span class="text-main text-xl pr-3">%</span>
            </div>
        </div>
    </template>
    <template #footer>
        <n-button
            strong
            class="rounded-md text-white/90 w-full"
            :color="gl.mainColor"
            :disabled="!canCreateNewOrderMatrix || newOrderMatrixLoading"
            :loading="newOrderMatrixLoading"
            @click="onNewOrderMatrix">
            {{ bots.localization['bot_orders_matrix_pro_button_modal_matrix_add_button'] }}
        </n-button>
    </template>
</base-drawer-mobile>


<base-drawer-mobile
    :label="bots.localization['bot_additional_settings_logarithmic_scale_view_orders_matrix_button']"
    v-model:show="viewOrdersMatrixModal">
    <template #default>
        <section>
            <div class="flex items-center justify-start">
                <span class="text-xs" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_current']"></span>
                <span
                    class="inline-block rb-td-stock-icon h-6 mx-2"
                    :class="`rb-td-stock-icon-${exchange(dataForm.exchange.value)?.code_name}`" >
                </span>
                <span class="text-xs" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_tickers_for']"></span>
                <div class="flex items-center mx-4">
                    <rb-coin class="w-6" :coin="currency(String(botShortInfo.pair).split('/').shift().trim())" />
                    <div class="text-xs text-gray-600 dark:text-white/75 ml-2">{{ botShortInfo.pair }}</div>
                </div>
            </div>
                
            <span class="text-xs" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_currenttickers_buy']"></span>&nbsp;
            <strong class="text-xs pl-1 text-green-400">{{ botShortInfo.buy }}</strong>
            <span class="text-xs pl-3" v-html="bots.localization['bot_orders_matrix_view_orders_matrix_modal_currenttickers_sell']"></span>&nbsp;
            <strong class="text-red-500 text-xs pl-1">{{ botShortInfo.sell }}</strong>
            <div class="mt-4 notes text-xs">
                <div v-if="dataForm.algo.value == 'long'" v-html="prepareNote(bots.localization['bot_orders_matrix_view_orders_matrix_modal_long_info'])"></div>
                <div v-else v-html="prepareNote(bots.localization['bot_orders_matrix_view_orders_matrix_modal_short_info'])"></div>
            </div>
        </section>
        <n-divider />

        <section>
            <div
                v-for="(item, i) in botShortInfo.orders_matrix"
                :key="i"
                class="text-xs mb-3">
                <div>
                    <span class="mr-2">{{ i + 1 }}.</span>
                    <span class="mr-2">{{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_amount'] }}</span>
                    <span class="mr-2 text-main">{{ item.amount }}</span>
                </div>
                <div>
                    <strong class="mr-2">{{ dataForm.algo.value === 'long' ? 'Buy' : 'Sell' }}</strong>
                    <span class="mr-2">{{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_rate'] }}</span>
                    <span class="mr-2 text-green-400">{{ item.rate }}</span>
                </div>
            </div>
        </section>
    </template>
    <template #footer>
        <n-button
            strong
            class="rounded-md text-white/90 w-full"
            :color="gl.mainColor"
            :loading="orderMatrixLoading"
            :disabled="orderMatrixLoading"
            @click="onViewOrderMatrix">
            {{ bots.localization['bot_orders_matrix_view_orders_matrix_modal_update'] }}
        </n-button>
    </template>
</base-drawer-mobile>

</template>

<script>
// vue
import { defineComponent } from 'vue';

// general
import general from './general';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbCoin from '@components/rb-coin';
import RbSelect from '@components/rb-select/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import RbMatrixCount from '@components/rb-matrix-count';

// naive-ui
import {
    NIcon,
    NCard,
    NModal,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NCheckbox,
    NInputNumber,
    NButtonGroup } from 'naive-ui';

// icons
import { Dismiss16Filled, CellularData220Filled, QuestionCircle20Filled } from '@vicons/fluent';

export default defineComponent({
    name: 'bot-settings',
    props: {
        exchangeInfoRef: {
            type: Object,
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        RbCoin,
        NModal,
        NAlert,
        NInput,
        NSelect,
        NButton,
        RbInput,
        NPopover,
        NDivider,
        RbSelect,
        NCheckbox,
        RbCheckbox,
        NInputNumber,
        NButtonGroup,
        RbMatrixCount,
        Dismiss16Filled,
        CellularData220Filled,
        QuestionCircle20Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
});
</script>