<template>
<section>
    <div class="flex">
        <div class="text-md text-gray-600 dark:text-white/75">{{ data.label }}</div>
        <n-popover
            trigger="hover"
            class="max-w-[500px] rounded-md bg-main"
            placement="top">
            <template #trigger>
                <n-icon
                    class="text-main cursor-pointer text-lg ml-2">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="notes text-xs text-white" v-html="data.dataHelp"></span>
        </n-popover>
    </div>

    <div class="mt-4">
        <n-radio-group
            :value="modelValue"
            @update:value="$emit('update:modelValue', $event)">
            <template
                v-for="radio in radios"
                :key="radio.value">
                <n-radio
                    v-if="radio.show"
                    :value="radio.value">
                    <div class="text-md text-gray-600 dark:text-white/75 text-left">{{ radio.label }}</div>
                </n-radio>
            </template>
        </n-radio-group>

      <n-card
          v-if="modelValue === 'internal'"
          class="mt-4 rounded-md"
          size="small">
          <p class="text-xs text-left">{{ bots.localization['commission_message_' + exchange(dataForm.exchange.value).code_name] }}</p>
          <div class="flex items-center justify-between mt-4">
              <div class="flex items-center">
                  <rb-coin class="w-6" :coin="currency(exchangeCommissionCoin)" />
                  <div class="text-md text-gray-600 dark:text-white/75 font-semibold ml-2 mt-1">{{ currency(exchangeCommissionCoin).title }}</div>
              </div>

              <n-button
                  strong
                  size="small"
                  class="rounded-md text-white/90"
                  :color="gl.mainColor"
                  :disabled="loading"
                  :loading="loading"
                  @click="onCheckCommissionTokenBalance">
                  {{ bots.localization['commission_button_' + exchange(dataForm.exchange.value).code_name] }}
              </n-button>
          </div>

          <n-alert
              v-if="commBalanceCheck"
              class="mt-4 rounded-md"
              :title="commBalanceCheck.result ? 'Success' : 'Error'"
              :type="commBalanceCheck.result ? 'success' : 'error'">
              <div class="text-md text-gray-600 dark:text-white/75">
                  {{ commBalanceCheck.msg }}
              </div>
          </n-alert>
      </n-card>
    </div>
</section>
</template>

<script>
// vue
import { ref, computed } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useRefs } from '@/store/refs';
import { useBots } from '@/store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import BotsService from '@services/bots';

// icons
import { Dismiss16Filled, QuestionCircle20Filled } from '@vicons/fluent';

// components
import RbCoin from '@components/rb-coin';

// naive-ui
import {
    NIcon,
    NCard,
    NRadio,
    NInput,
    NModal,
    NSelect,
    NButton,
    NAlert,
    NDivider,
    NPopover,
    NCheckbox,
    useMessage,
    NRadioGroup } from 'naive-ui';
    
export default {
    name: 'bot-wallet-commissions',
    emits: ['update:modelValue'],
    props: {
        isUpdating: Boolean,
        modelValue: [String, Number],
        data: {
            type: Object,
            required: true,
        },
        dataForm: {
            type: Object,
            required: true,
        },
        exchangeCommissionCoin: {
            type: [String, Number],
            required: true,
        },
    },
    components: {
        NIcon,
        NCard,
        NModal,
        RbCoin,
        NRadio,
        NInput,
        NAlert,
        NButton,
        NSelect,
        NPopover,
        NDivider,
        NRadioGroup,
        Dismiss16Filled,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();

        // i18n
        const { t } = useI18n();

        //naive-ui
        

        // vars
        const loading = ref(false);
        const commBalanceCheck = ref();

        const radios = computed(() => [
            {
                value: 'standard',
                show: !props.isUpdating ? !props.exchangeCommissionCoin : props.modelValue === 'standard',
                label: ' - standard commission',
            }, {
                value: 'internal',
                show: !props.isUpdating ? true : props.modelValue === 'internal',
                label: bots.localization['commission_i_' + exchange(props.dataForm.exchange.value).code_name],
            },
        ]);

        const currency = (icon) => refs.currencies.find(el => el.id === icon);

        const onCheckCommissionTokenBalance = async () => {
            loading.value = true;

            try {
                var result = await BotsService.checkCommissionTokenBalance({
                    exchange: props.dataForm.exchange.value,
                    wallet: props.dataForm.wallet.value,
                    api_key: props.dataForm.api_key.value,
                    symbol: props.exchangeCommissionCoin,
                    algo: props.dataForm.algo.value,
                    pair: props.dataForm.pair.value,
                });
            } catch {
                loading.value = false;
                return void gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            }
            
            commBalanceCheck.value = result.data;

            loading.value = false;
        };

        const exchange = exchange => refs.exchanges.find(el => el.id === exchange);

        return {
            gl,
            bots,
            radios,
            loading,
            exchange,
            commBalanceCheck,
            currency,
            onCheckCommissionTokenBalance,
        };
    },
};
</script>