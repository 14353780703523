<template>
<base-modal-desktop
    :label="applyLabel"
    :show="showModal"
    @update:show="$emit('close')">
    <template #default>
        <div v-if="!isInit" class="flex h-full justify-center">
            <n-spin size="small" />
        </div>
        <template v-else>
            <div class="flex items-center">
                <div>
                    <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">
                        {{ bots.localization['bots_new_wallet_exchange'] }}
                    </div>
                </div>
                <span
                    class="inline-block rb-td-stock-icon ml-4 w-18"
                    :class="`rb-td-stock-icon-${getExchange(exchangeRef)?.code_name}`" >
                </span>
            </div>

            <n-divider class="mt-0" />
            <div class="flex items-center">
                <div class="mr-4 flex justify-between">
                    <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">
                        {{ dataForm.currency.title }}
                    </div>
                </div>
                <div v-if="isUpdating" class="flex items-center">
                    <rb-coin class="w-6" :coin="getCurrency(coinSymbolForWallet)" />
                    <div class="text-md text-gray-600 dark:text-white/75 ml-2">{{ getCurrency(coinSymbolForWallet).title }}</div>
                </div>
                <n-select
                    v-else
                    filterable
                    class="flex-grow"
                    :options="dataForm.currency.options"
                    :render-label="renderLabel"
                    :placeholder="dataForm.currency.placeholder"
                    :value="dataForm.currency.value !== -1 ? dataForm.currency.value : null"
                    @update:value="dataForm.currency.value = $event" />
            </div>
            
            <div class="flex items-center mt-4">
                <div class="flex items-center">
                    <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap" :class="dataForm.amount.status === 'error' ? 'text-red-400' : ''">
                        {{ dataForm.amount.title }}
                    </div>
                    <n-popover
                        v-if="dataForm.amount.info"
                        trigger="hover"
                        class="max-w-[500px] rounded-md bg-yellow-600"
                        placement="top">
                        <template #trigger>
                            <n-icon
                                class="cursor-pointer text-lg ml-2 text-yellow-600">
                                <Warning24Filled />
                            </n-icon>
                        </template>
                        <span class="notes text-xs text-white" v-html="dataForm.amount.info"></span>
                    </n-popover>
                </div>
                <div class="flex-grow flex flex-wrap ml-4">
                    <n-radio-group
                        class="rounded-md"
                        :disabled="dataForm.type.loading"
                        v-model:value="dataForm.type.value">
                        <n-radio-button
                            v-for="option in dataForm.type.options"
                            :class="dataForm.type.value === option.value ? 'text-white/90' : ''"
                            :key="option.value"
                            :value="option.value"
                            :label="option.label" />
                    </n-radio-group>

                    <div class="flex-grow ml-4">
                        <rb-input
                            :status="dataForm.amount.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.amount.msg"
                            :disabled="dataForm.amount.loading"
                            :placeholder="dataForm.amount.placeholder"
                            v-model:value="dataForm.amount.value"
                            @update:value="dataForm.amount.status = null, dataForm.amount.msg = null" />
                    </div>
                </div>
            </div>
            
            <n-divider />
            <div class="flex mt-4 items-end">
                <rb-select
                    filterable
                    class="flex-grow"
                    :label="dataForm.api_key.title"
                    :options="dataForm.api_key.options"
                    :loading="dataForm.api_key.loading"
                    :disabled="dataForm.api_key.loading"
                    :status="dataForm.api_key.status === 'error' ? 'error' : undefined"
                    :msg="dataForm.api_key.msg"
                    v-model:value="dataForm.api_key.value"
                    @update:value="dataForm.api_key.status = null, dataForm.api_key.msg = null" />
                <n-button
                    strong
                    class="ml-2 rounded-md text-white/90 font-normal"
                    :color="gl.mainColor"
                    :disabled="dataForm.api_key.disabledButton || dataForm.api_key.loading"
                    :loading="dataForm.api_key.loading"
                    @click="onCheckBalance">
                    {{ dataForm.api_key.buttonLabel }}
                </n-button>
            </div>
            <div class="w-full flex justify-between mt-4">
                <n-statistic v-if="symbolBalance" :label="bots.localization['bots_new_wallet_checkbalance_available']" tabular-nums>
                    <n-number-animation
                        :from="0"
                        :to="+symbolBalance"
                        :precision="8"
                    />
                </n-statistic>
                <n-statistic v-if="symbolTotalBalance" :label="bots.localization['bots_new_wallet_checkbalance_total']" tabular-nums>
                    <n-number-animation
                        :from="0"
                        :to="+symbolTotalBalance"
                        :precision="8"
                    />
                </n-statistic>
            </div>

            <n-divider />
            <rb-input
                label-position="left"
                :label="dataForm.name.title"
                :status="dataForm.name.status === 'error' ? 'error' : undefined"
                :msg="dataForm.name.msg"
                :disabled="dataForm.name.loading"
                :placeholder="dataForm.name.placeholder"
                v-model:value="dataForm.name.value"
                @update:value="dataForm.name.status = null, dataForm.name.msg = null" />

            <rb-input
                class="mt-4"
                type="textarea"
                label-position="left"
                :label="dataForm.comment.title"
                :status="dataForm.comment.status === 'error' ? 'error' : undefined"
                :msg="dataForm.comment.msg"
                :disabled="dataForm.comment.loading"
                :placeholder="dataForm.comment.placeholder"
                v-model:value="dataForm.comment.value"
                @update:value="dataForm.comment.status = null, dataForm.comment.msg = null" />
        </template>
    </template>
    <template #footer>
        <div class="flex justify-end">
            <n-button
                strong
                class="rounded-md text-white/90"
                :color="gl.mainColor"
                :loading="loading"
                :disabled="!canCreateWallet || loading"
                @click="onReadyDataForm">
                {{ applyLabelBtn }}
            </n-button>
        </div>
    </template>
</base-modal-desktop>
</template>

<script>
// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// services
import WalletsService from '@services/walletsService';

// components
import RbCoin from '@components/rb-coin';
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';

// icons
import { Warning24Filled, Dismiss16Filled, QuestionCircle20Filled } from '@vicons/fluent';

// shared
import { WALLET_DATA_FORM_FACTORY } from '@shared/factories'; 
import { formatUnexistedCurrsAsUnknown } from '@shared/util';

// naive-ui
import {
    NIcon,
    NSpin,
    NCard,
    NInput,
    NModal,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NStatistic,
    NRadioGroup,
    NRadioButton,
    NNumberAnimation,
    NCheckbox,
    useMessage } from 'naive-ui';

export default {
    name: 'rb-wallet-cru-desktop',
    emits: ['update:modelValue'],
    props: {
        exchangeRef: [String, Number],
        disabled: Boolean,
        showModal: Boolean,
        apiKey: {
            type: [String, Number],
            default: -1,
        },
        buttonLabel: {
            type: String,
            required: true,
        },
        mode: {
            type: String,
            default: 'create',
        },
        data: {
            type: Object,
            required: true,
        },
        coinSymbolForWallet: {
            type: String,
            default: null,
        },
        apiKeysRefs: {
            type: Array,
            required: true,
        },
        currenciesRefs: {
            default: [],
        },
    },
    components: {
        NIcon,
        NSpin,
        NCard,
        NModal,
        RbCoin,
        NInput,
        RbInput,
        NButton,
        NSelect,
        NPopover,
        NDivider,
        RbSelect,
        NStatistic,
        NRadioGroup,
        NRadioButton,
        Warning24Filled,
        Dismiss16Filled,
        NNumberAnimation,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();

        // i18n
        const { t } = useI18n();

        //naive-ui
        

        // vars
        const isInit = ref(false);
        const loading = ref(false);
        const balancesRefs = ref();
        watch(() => props.showModal, v => {
            if (v) init();
        });
        const dataForm = reactive({});

        const applyLabel = computed(() =>
            dataForm.id
                ? bots.localization['bots_new_wallet_update_title']
                : bots.localization['bots_new_wallet_title']);

        const applyLabelBtn = computed(() =>
            dataForm.id
                ? bots.localization['bots_new_wallet_update_button']
                : bots.localization['bots_new_wallet_create_button']);
        
        const isUpdating = computed(() => props.mode === 'edit');
        const currencies = computed(() => {
            if (!Array.isArray(props.currenciesRefs)) return [];
            return formatUnexistedCurrsAsUnknown(refs, props.currenciesRefs);
        });

        const currentWallet = computed(() =>
            props.mode === 'create'
                ? null
                : props.data.walletsRefs.find(w => w.id === props.data.value));

        const symbolBalance = computed(() => balancesRefs.value?.[dataForm.currency.value] || '');
        const symbolTotalBalance = computed(() => balancesRefs.value?.[`${dataForm.currency.value}_total`] || '');
        const canCreateWallet = computed(() => dataForm.name.value.trim().length > 0);

        const canCheckBalance = computed(() =>
            dataForm.currency.value && dataForm.api_key.value
            && (dataForm.currency.value != -1 && dataForm.api_key.value != -1));

        const getExchange = (exchange) => {
            return refs.exchanges.find(el => el.id === exchange);
        };

        const getCurrency = (icon) => {
            return refs.currencies.find(el => el.id === icon);
        };

        function init() {
            const res = WALLET_DATA_FORM_FACTORY({
                id: currentWallet.value?.id || null,
                exchange: props.exchangeRef,
                api_key: +props.apiKey,
                currency: props.coinSymbolForWallet,
                name: currentWallet.value?.name || '',
                comment: currentWallet.value?.comment || '',
                type: currentWallet.value?.type || 1,
                amount: currentWallet.value?.amount || 0,
            });

            for (const [key, value] of Object.entries(res)) {
                if (key === 'currency') {
                    dataForm[key] = {
                        value,
                        title: bots.localization['bots_new_wallet_currency_f'],
                        placeholder: `- ${t('select')} -`,
                        options: computed(() => currencies.value.map(el => ({
                            label: el.title,
                            value: el.id,
                            ...el,
                        }))),
                        status: undefined,
                        msg: undefined,
                    };
                } else if (key === 'type') {
                    dataForm[key] = {
                        value,
                        options: computed(() => refs.walletTypes.map(el => ({
                            value: el.id,
                            label: el.title,
                            ...el,
                            status: undefined,
                            msg: undefined,
                            loading: false,
                        }))),
                    };
                } else if (key === 'amount') {
                    dataForm[key] = {
                        value,
                        title: bots.localization['bots_new_wallet_amount_f'],
                        placeholder: bots.localization['bots_new_wallet_amount_i'],
                        info: bots.localization['bots_minimum_depo_info'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                    };
                } else if (key === 'api_key') {
                    dataForm[key] = {
                        value,
                        title: bots.localization['bots_new_wallet_apikey_f'],
                        buttonLabel: bots.localization['bots_new_wallet_checkbalance_button'],
                        disabledButton: computed(() => !canCheckBalance.value),
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        options: computed(() => props.apiKeysRefs.map(el => ({
                            value: el.id,
                            label: el.name,
                            ...el,
                        }))),
                    };
                } else if (key === 'name') {
                    dataForm[key] = {
                        value,
                        title: bots.localization['bots_new_wallet_name_f'],
                        placeholder: bots.localization['bots_new_wallet_name_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                    };
                } else if (key === 'comment') {
                    dataForm[key] = {
                        value,
                        title: bots.localization['bots_new_wallet_comment_f'],
                        placeholder: bots.localization['bots_new_wallet_comment_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                    };
                } else dataForm[key] = value;
            }

            isInit.value = true;
        };

        const renderLabel = option => {
            return h(
                'div',
                { class: 'flex items-center py-1' },
                [
                    h(RbCoin, {
                        class: 'mr-2 w-4',
                        coin: option,
                    }),
                    h(
                        'div',
                        { class: 'text-sm' },
                        [
                            h('div', null, [option.label]),
                        ],
                    ),
                ],
            );
        };

        const onCheckBalance = async () => {
            dataForm.api_key.loading = true;

            const prepare = await WalletsService.getBalances(dataForm.api_key.value);

            if (!prepare.data.status) {
                prepare.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });

                // reset balancesRefs
                balancesRefs.value = null;
            } else {
                balancesRefs.value = prepare.data.balances;
            }

            dataForm.api_key.loading = false;
        };

        const onReadyDataForm = async () => {
            loading.value = true;

            const records = {
                exchange: dataForm.exchange,
                api_key: dataForm.api_key.value,
                name: dataForm.name.value,
                comment: dataForm.comment.value,
                type: dataForm.type.value,
                amount: dataForm.amount.value,
                currency: dataForm.currency.value,
            };

            let prepare;

            try {
                prepare = props.mode === 'create'
                    ? await WalletsService.addNew(records)
                    : await WalletsService.udpate({
                        id: currentWallet.value.id,
                        ...records,
                    });
            } catch {
                gl.showNotification({
                    type: 'error',
                    msg: t('errorMessage'),
                });
            }

            if (prepare) {
                if (!prepare.data.status) {
                    if (prepare.data?.errors_form) {

                        for (let key in dataForm) {
                            const fields = prepare.data.errors_form[props.mode === 'create' ? 'i_0' : 'update'].fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (dataForm[key] && typeof dataForm[key] === 'object') {
                                if (el) {
                                    dataForm[key].status = 'error';
                                    dataForm[key].msg = fields[el].msg;
                                } else {
                                    dataForm[key].status = 'success';
                                    dataForm[key].msg = undefined;
                                }
                            }
                        }
                    }
                } else {
                    // show messages
                    prepare.postMessages.forEach(el => {
                        gl.showNotification({
                            type: el.success ? 'success' : 'error',
                            msg: el.msg,
                        });
                    });
                    
                    if (props.mode === 'create') {
                        emit('walletReady', {
                            id: prepare.data.records[0].id,
                            api_key: dataForm.api_key.value,
                        });
                    } else {
                        emit('walletUpdate', prepare.data.record);
                    }

                    emit('close');
                }
            }

            loading.value = false;
        };

        onMounted(() => {
            // console.log('apiKey', props.data);
        });

        return {
            gl,
            bots,
            isInit,
            loading,
            dataForm,
            isUpdating,
            applyLabel,
            currencies,
            symbolBalance,
            currentWallet,
            applyLabelBtn,
            canCheckBalance,
            canCreateWallet,
            symbolTotalBalance,
            t,
            getExchange,
            renderLabel,
            getCurrency,
            onCheckBalance,
            onReadyDataForm,
        };
    },
};
</script>